import React, { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ScrollTop, Slideout, Spinner, UnauthorisedCard } from 'components';
import { useDebounce } from 'hooks';
import { CommunicationProviderService } from 'services';

import type { CommunicationProvider } from 'types';
import ProviderCard from './provider-card';
import { getAccessToken } from 'utils';
import { apiConfig } from 'auth-config';
import ProviderViewContainer from './provider-view-container';
import ProviderEdit from './provider-edit';
import { ProviderActionsDropdown } from './provider-components';

const CommunicationProviders: React.FC = () => {
	const isAuthenticated = useIsAuthenticated();
	if (!isAuthenticated)
		return (
			<main className='p-3 pb-6'>
				<UnauthorisedCard />
			</main>
		);

	// Search State
	const [searchInput, setSearchInput] = useState<string>('');
	const [providers, setProviders] = useState<CommunicationProvider[]>([]);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(0);
	const msal = useMsal();
	const ofcomApiScopes = apiConfig.ofcomApi.scopes;

	const searchTerm = useDebounce<string>(searchInput, 300);
	const dataLength = 5;
	const pageSize = 6;

	// View State
	const [slideoutOpen, setSlideoutOpen] = useState(false);
	const [slideoutChild, setSlideoutChild] = useState<React.ReactNode>(null);
	const [slideoutTitle, setSlideoutTitle] = useState('');

	// Functions and effects
	const handleTermChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		e.preventDefault();

		// reset page and more values then set state
		setHasMore(true);
		setPage(0);
		setSearchInput(e.target.value);
	};

	// TODO Dynamically set pagesize based on screen size
	const fetchProviders = async () => {
		const token: string = await getAccessToken(ofcomApiScopes, msal);
		return await CommunicationProviderService.searchProviders(token, searchTerm, page, pageSize);
	};

	const fetchNextData = async () => {
		const nextData = await fetchProviders();

		setProviders([...providers, ...nextData]);
		if (nextData.length === 0 || nextData.length < dataLength) {
			setHasMore(false);
		}

		setPage(page + 1);
	};

	const addProvider = async () => {
		setSlideoutTitle('Add New Communication Provider');
		setSlideoutChild(
			<ProviderEdit
				provider={null}
				setEditMode={(b) => null}
				setSearchInput={setSearchInput}
				onClose={() => setSlideoutOpen(false)}
				isProviderAddForm={true}
			/>,
		);
		setSlideoutOpen(true);
	};

	const onProviderSelection = async (cupid: string): Promise<void> => {
		setSlideoutOpen(true);
		const token: string = await getAccessToken(ofcomApiScopes, msal);
		const provider = await CommunicationProviderService.getProvider(token, cupid);
		setSlideoutTitle(`(${provider.cupid}) ${provider.name}`);
		setSlideoutChild(<ProviderViewContainer provider={provider} setSearchInput={setSearchInput} onClose={() => setSlideoutOpen(false)} />);
	};

	useEffect(() => {
		const getUpdatedProviders = async () => {
			const updatedData = await fetchProviders();
			setProviders(updatedData);

			if (updatedData.length === 0 || updatedData.length < dataLength) {
				setHasMore(false);
			}
		};
		getUpdatedProviders();
		setPage(page + 1);
	}, [searchTerm]);

	return (
		<main className='p-3 pb-6'>
			<Slideout open={slideoutOpen} setOpen={setSlideoutOpen} title={slideoutTitle} child={slideoutChild} />
			<h1 className='text-3xl tracking-tight font-extrabold sm:text-4xl md:text-4xl lg:text-5xl xl:text-5xl' data-cy='provider-heading'>
				Communication Providers
			</h1>
			<hr className='border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8' />
			{/* Search Input Box */}
			<div className='w-8/12 md:w-4/12 mt-2 relative rounded-full shadow-sm'>
				<div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
					<MagnifyingGlassIcon aria-hidden='true' className='h-5 w-5 text-gray-400' />
				</div>
				<input
					type='text'
					name='searchInput'
					id='searchInput'
					value={searchInput}
					className='focus:ring-gamma-digital focus:border-gamma-digital block w-full pl-10 sm:text-sm border-gray-300 px-4 rounded-full shadow-sm text-black dark:text-white dark:bg-gray-800'
					placeholder='Search Communication Providers'
					onChange={(e) => handleTermChange(e)}
				/>
			</div>

			{/* Actions menu */}
			<div className='my-4'>
				<ProviderActionsDropdown onAddProvider={addProvider} />
			</div>

			{/* Results selection */}
			<div className='bg-white dark:bg-gray-700 shadow overflow-hidden sm:rounded-md mt-2 md:w-8/12'>
				<ul>
					<InfiniteScroll
						dataLength={providers.length}
						next={fetchNextData}
						hasMore={hasMore}
						loader={<ProviderLoader />}
						className='divide-y divide-gray-200'
					>
						{providers.map((provider) => {
							return (
								<li key={provider.cupid}>
									<ProviderCard provider={provider} onSelection={onProviderSelection} />
								</li>
							);
						})}
					</InfiniteScroll>
				</ul>
			</div>
			<ScrollTop />
		</main>
	);
};

export default CommunicationProviders;

/**
 * Simple function that returns a loading indicator when more results are being fetched
 * @return {JSX.Element}: Loading element
 */
function ProviderLoader(): JSX.Element {
	return (
		<div className='mt-2'>
			<Spinner />
			<span className='text-gray-500 text-sm pl-2'>Loading providers...</span>
		</div>
	);
}
