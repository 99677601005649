import React from 'react';

import { useAppContext } from 'contexts';

const UnauthorisedCard = () => {
	const app = useAppContext();

	return (
		<div className='min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8'>
			<div className='max-w-max mx-auto'>
				<main className='sm:flex'>
					<p className='text-4xl font-extrabold text-gamma-digital sm:text-5xl'>401</p>
					<div className='sm:ml-6'>
						<div className='sm:border-l sm:border-gray-200 dark:sm:border-gray-600 sm:pl-6'>
							<h1 className='text-4xl font-extrabold tracking-tight sm:text-5xl'>Unauthorised</h1>
							<p data-testid='sign-in-text' className='mt-1 text-base'>
								Please sign in to access this content!
							</p>
						</div>
						<div className='mt-10 flex space-x-3 sm:border-1 sm:border-transparent sm:pl-6'>
							<button
								onClick={app.signIn}
								className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gamma-digital hover:bg-gamma-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-gamma-digital'
								data-testid='sign-in-button'
							>
								Sign In
							</button>
							<a
								href='mailto:david.haasz@gamma.co.uk'
								className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
							>
								Contact support
							</a>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
};

export default UnauthorisedCard;
