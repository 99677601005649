import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import Select from 'react-select';
import { apiConfig } from 'auth-config';
import { ActionsDropdown, Slideout, SubmitDialog, SubmitDialogProps, UnauthorisedCard } from 'components';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CommunicationProviderService, PortAgreementService } from 'services';
import { Option, PortAgreementItem, PortAgreementSearch } from 'types';
import { getAccessToken, PORT_AGREEMENT_SINGLE_ADMIN_ROLE, getDefaultDialogProps } from 'utils';
import PortAgreementTable from './port-agreement-table';
import PortAgreementViewContainer from './port-agreement-view-container';
import PortAgreementFormContainer from './port-agreement-form-container';

const pageSize = 5;

const PortAgreements: React.FC = () => {
	const isAuthenticated = useIsAuthenticated();
	if (!isAuthenticated)
		return (
			<main className='p-3 pb-6'>
				<UnauthorisedCard />
			</main>
		);

	// setup state
	const [agreements, setAgreements] = useState<PortAgreementItem[]>([] as PortAgreementItem[]);
	const [numberRange, setNumberRange] = useState('');
	const [agreementHolder, setAgreementHolder] = useState('');

	const [slideoutChild, setSlideoutChild] = useState<React.ReactNode>(() => null);
	const [slideoutTitle, setSlideoutTitle] = useState('');
	const [slideoutOpen, setSlideoutOpen] = useState(false);
	const [dialogProps, setDialogProps] = useState<SubmitDialogProps>(getDefaultDialogProps());

	const msal = useMsal();

	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const ofcomApiScopes = apiConfig.ofcomApi.scopes;

	// functions
	const getAgreements = (): PortAgreementItem[] => {
		return agreements;
	};

	const onAgreementSelection = async (agreementId: number) => {
		const token: string = await getAccessToken(ofcomApiScopes, msal);
		setSlideoutOpen(true);
		const agreement = await PortAgreementService.getPortAgreementItem(token, agreementId);
		setSlideoutTitle(`Port Agreement: +44${agreement.numberBlock} (${agreement.agreementHolder.cupid}) ${agreement.agreementHolder.name}`);
		setSlideoutChild(
			<PortAgreementViewContainer
				agreement={agreement}
				onClose={() => setSlideoutOpen(false)}
				openDeleteDialogFunction={openDeleteDialog}
			/>,
		);
	};

	const onAddRange = async () => {
		setSlideoutTitle('Add New Port Agreement');
		setSlideoutChild(
			<PortAgreementFormContainer agreement={null} setEditMode={(b) => null} onClose={() => setSlideoutOpen(false)} action='ADD' />,
		);
		setSlideoutOpen(true);
	};

	const openDeleteDialog = (agreement: PortAgreementItem) => {
		const dProps: SubmitDialogProps = {
			title: `Delete Port Agreement for +44${agreement.numberBlock} with (${agreement.agreementHolder.cupid}) ${agreement.agreementHolder.name}`,
			child: getDeleteDialogChild(agreement),
			submitFunction: (reason) => deleteAgreement(agreement),
			cancelFunction: () => setDialogProps(getDefaultDialogProps()),
			showChangeReason: false,
			successMessage: `Successfully deleted port agreement +44${agreement.numberBlock} with (${agreement.agreementHolder.cupid}) ${agreement.agreementHolder.name}`,
			defaultFailMessage: `Failed to delete port agreement +44${agreement.numberBlock} with (${agreement.agreementHolder.cupid}) ${agreement.agreementHolder.name}`,
		};
		setDialogProps(dProps);
	};

	const getDeleteDialogChild = (agreement: PortAgreementItem): React.ReactNode => {
		return (
			<p className='text-left text-sm'>
				The porting agreement for +44{agreement.numberBlock} with ({agreement.agreementHolder.cupid}) {agreement.agreementHolder.name}{' '}
				against port prefix {agreement.portPrefix}
				will be removed. After this agreement has been deleted no more standard port orders can be raised for this range with this range
				holder. Going forward ports will have to be raised through the IPEX process. Please ensure the data is correct prior to making the
				changes.
			</p>
		);
	};

	const deleteAgreement = async (agreement: PortAgreementItem): Promise<Response> => {
		const token = await getAccessToken(ofcomApiScopes, msal);
		const response = await PortAgreementService.deletePortAgreement(token, agreement.id);
		if (response.ok || (response.status > 200 && response.status < 299)) {
			setSlideoutOpen(false);
			setNumberRange('');
			setAgreementHolder('');
			fetchAgreements('', '', 0);
		}
		return response;
	};

	const handleNumberRangeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		e.preventDefault();
		setNumberRange(e.target.value);
	};

	const handleSearch = (e: React.MouseEvent<HTMLButtonElement>): void => {
		e.preventDefault();

		setPage(0);
		fetchAgreements(numberRange, agreementHolder, 0);
	};

	const fetchAgreements = async (numberRange: string, agreementHolder: string, page: number) => {
		const token = await getAccessToken(ofcomApiScopes, msal);

		// build search input
		const searchRequest: PortAgreementSearch = {
			startDigits: numberRange && numberRange !== '' ? numberRange.replace(/^0+/, '') : undefined,
			cupid: agreementHolder && agreementHolder !== '' ? agreementHolder : undefined,
		};

		PortAgreementService.searchPortAgreements(token, searchRequest, page, pageSize).then((r) => {
			console.log(r);
			setAgreements(r);
			setHasMore(!(r.length < pageSize));
		});
	};

	const nextPage = () => {
		if (hasMore) {
			const newPage = page + 1;
			setPage(newPage);
			fetchAgreements(numberRange, agreementHolder, newPage);
		}
	};

	const previousPage = () => {
		if (page > 0) {
			const newPage = page - 1;
			setPage(newPage);
			fetchAgreements(numberRange, agreementHolder, newPage);
		}
	};

	// effects
	useEffect(() => {
		fetchAgreements('', '', 0);
	}, []);

	// additional state
	const actions = [
		{
			label: 'Add Agreement',
			userRole: PORT_AGREEMENT_SINGLE_ADMIN_ROLE,
			onSelection: onAddRange,
		},
	];

	// JSX
	return (
		<main className='p-3 pb-6'>
			{/* eslint-disable-next-line react/no-children-prop */}
			<Slideout open={slideoutOpen} setOpen={setSlideoutOpen} title={slideoutTitle} child={slideoutChild} />
			<SubmitDialog
				title={dialogProps.title}
				child={dialogProps.child}
				submitFunction={dialogProps.submitFunction}
				cancelFunction={dialogProps.cancelFunction}
				showChangeReason={dialogProps.showChangeReason}
				successMessage={dialogProps.successMessage}
				defaultFailMessage={dialogProps.defaultFailMessage}
			/>
			<h1 className='text-3xl tracking-tight font-extrabold sm:text-4xl md:text-4xl lg:text-5xl xl:text-5xl'>Port Agreements</h1>
			<hr className='border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8' />

			<form className='space-y-8'>
				<div className='space-y-8'>
					<div>
						<div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 lg:w-1/2'>
							{/* Number Range Input */}
							<div className='sm:col-span-4'>
								<label htmlFor='number-range' className='block text-sm font-medium text-gray-800 dark:text-white'>
									Number Range
								</label>
								<div className='mt-1 flex rounded-md shadow-sm'>
									<span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-300 px-3 text-gray-600 sm:text-sm dark:bg-gray-700 dark:text-white'>
										+44
									</span>
									<input
										type='text'
										name='numberRange'
										id='number-range'
										value={numberRange}
										onChange={(e) => handleNumberRangeChange(e)}
										className='block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-gamma-digital focus:ring-gamma-digital sm:text-sm dark:text-white dark:bg-gray-800'
									/>
								</div>
							</div>
							{/* Porting Partner */}
							<div className='sm:col-span-4'>
								<label htmlFor='portPartner' className='block text-sm font-medium text-gray-800 dark:text-white'>
									Communications Provider
								</label>
								<div className='mt-1'>
									<PortingPartnersDropdown id='port-partner' setValue={setAgreementHolder} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='pt-5 pb-5'>
					<div className='flex justify-end lg:justify-start'>
						<button
							type='submit'
							data-testid='advanced-search-button'
							className='inline-flex justify-center rounded-md border border-transparent bg-gamma-digital py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gamma-hover focus:outline-none focus:ring-2 focus:ring-gamma-digital focus:ring-offset-2'
							onClick={(e) => handleSearch(e)}
						>
							Search
						</button>
					</div>
				</div>
			</form>

			{/* Actions Dropdown */}
			{actions.length > 0 ? <ActionsDropdown pageTitle='Port Agreements' actions={actions} /> : null}

			{/* Results selection */}
			<PortAgreementTable
				agreements={getAgreements()}
				hasPrevious={page > 0}
				hasNext={hasMore}
				page={page}
				pageSize={pageSize}
				onPrevious={() => previousPage()}
				onNext={() => nextPage()}
				onSelection={onAgreementSelection}
			/>
		</main>
	);
};

export default PortAgreements;

interface PartnersDownProps {
	id: string;
	setValue: Dispatch<SetStateAction<string>>;
}

/**
 * Simple function that returns a searchable porting partner drop down
 * @param {PartnersDownProps}: partner drop down props
 * @return {JSX.Element}: Porting partner drop down for searching
 */
function PortingPartnersDropdown({ id, setValue }: PartnersDownProps): JSX.Element {
	const [portingPartners, setPortingPartners] = useState<Option[]>([] as Option[]);
	const msal = useMsal();
	const ofcomApiScopes = apiConfig.ofcomApi.scopes;

	useEffect(() => {
		const initialise = async () => {
			const token = await getAccessToken(ofcomApiScopes, msal);
			const providers = await CommunicationProviderService.getParentProviders(token);

			const options = [] as Option[];
			options.push({
				label: 'Any',
				value: '',
			});

			providers.forEach((item) => {
				if (item.flags.portingPartner) {
					// only add porting partners as options
					options.push({
						label: `(${item.cupid}) ${item.name}${item.franchiseArea ? ` ${item.franchiseArea}` : ''}`,
						value: item.cupid,
					});
				}
			});

			setPortingPartners(options);
		};

		initialise();
	}, []);

	return (
		<div className='block w-full rounded-md border-gray-300 shadow-sm focus:border-gamma-digital focus:ring-gamma-digital sm:text-sm'>
			<Select
				aria-labelledby={id}
				id={id}
				options={portingPartners}
				inputId={id}
				isMulti={false}
				isDisabled={false}
				name={id}
				onChange={(newValue) => {
					const selectedOption = newValue as Option;
					setValue(selectedOption.value);
				}}
				className='searchable-select-container'
				classNamePrefix='searchable-select'
			/>
		</div>
	);
}
